<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-coupon ref="addCoupon"></add-coupon>
    <div class="app-container">
      <div class="app-content">
        <div style="width: 100%;">
          <div class="is-flex mb-6">
            <div class="is-size-5">KUPONI</div>
            <b-button class="ml-auto" type="is-primary" icon-left="plus" @click="onAddNew">DODAJ NOVI</b-button>
          </div>
          <div style="display: flex; flex-wrap: wrap; flex-direction: row">
            <coupon-card v-for="coupon in coupons" :key="coupon.id" :coupon="coupon" @onEditCoupon="onEdit"></coupon-card>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {getDate} from "@/utils/utils";
import AddCoupon from "@/components/add-coupon/add-coupon.vue";
import CouponCard from "@/components/coupon-card/coupon-card.vue";

export default {
  name: "app-coupons",
  components: {CouponCard, AddCoupon},
  data() {
    return {
      isLoading: false
    }
  },
  async mounted() {
    this.isLoading = true
    await this.$store.dispatch('getCoupons')
    this.isLoading = false

    console.log('Coupons: ', this.$store.state.coupons)
  },
  methods: {
    async onAddNew() {
      console.log('On add new clicked')
      this.$refs.addCoupon.show(true, null)
    },
    onEdit(coupon){
      this.$refs.addCoupon.show(true, coupon)
    }
  },
  computed: {
    coupons() {
      return this.$store.getters.coupons
    }
  }
}
</script>

<style scoped>

</style>