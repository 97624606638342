<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div class="app-container">
      <div class="app-content">
        <h4 class="is-size-4">Kupon za dodelu</h4>
        <div class="is-flex">
          <coupon-card v-if="coupon" :coupon="coupon" :show-actions="false"></coupon-card>
          <div class="ml-auto">
            <div class="box is-flex is-flex-direction-column p-3" style="min-width: 300px; height: 142px">
              <div>Selektovano korisnika: {{checkedRows.length}}</div>
              <div v-if="assignedToUsers > 0">Broj korisnika sa selektovanim kuponom: {{this.assignedToUsers}}</div>
              <b-button class="mt-auto" type="is-primary" :disabled="checkedRows.length === 0" @click="assignCoupon">Dodeli korisnicima</b-button>
            </div>
          </div>
        </div>

        <h4 class="is-size-4">Dostupni Korisnici</h4>
        <div class="box p-4 mb-6">
                  <b-table
                      key="incident_table"
                      ref="table"
                      :data="cardTableData"
                      :checked-rows.sync="checkedRows"
                      checkable
                      checkbox-position="left"
                      checkbox-type="is-primary"
                      hoverable
                      paginated
                      per-page="5"
                      current-page.sync="1"
                      :pagination-simple="false"
                      pagination-position="bottom"
                      default-sort-direction="asc"
                      :pagination-rounded="false"
                      sort-icon="arrow-up"
                      sort-icon-size="is-small"
                      @sort="sortClicked"
                      default-sort="user.first_name"
                      aria-next-label="Next page"
                      aria-previous-label="Previous page"
                      aria-page-label="Page"
                      aria-current-label="Current page"
                      :page-input="false">
                    <b-table-column field="userId" label="Korisnik">
                      <template v-slot:header="{ column }" >
                        <table-header :column="column" element-id="223423" header-type="none" style="width: 100%"
                                      :table-header-data="tableHeaderData" @onSort="onSort">
                        </table-header>
                      </template>
                      <template v-slot="props">
                        <div class="media mr-4" v-if="props.row.user != null">
                          <user-avatar :user="props.row.user" :is-small="true"></user-avatar>
                          <div class="media-content" style="margin-left: 8px; margin-top: 4px;">
                            <h6 class="is-6 has-text-weight-semibold" style="margin-bottom: 0; line-height: 0.5;">{{ props.row.user.firstName}} {{props.row.user.lastName}}</h6>
                            <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">{{ props.row.user.email }}</small>
                          </div>
                        </div>
                      </template>
                    </b-table-column>
                    <b-table-column field="available" label="DOSTUPNI POENI" sortable>
                      <template v-slot:header="{ column }" >
                        <table-header :column="column" header-type="search" tooltip="Filter Points" element-id="4674435" style="width: 100%"
                                      :table-header-data="tableHeaderData" @onSort="onSort">
                        </table-header>
                      </template>
                      <template v-slot="props">
                        {{ props.row.available }} points
                      </template>
                    </b-table-column>
                    <b-table-column field="usedCoupons" label="Iskorišćeni kuponi" sortable>
                      <template v-slot:header="{ column }">
                        <table-header :column="column" element-id="22342386975" header-type="none"
                                      :table-header-data="tableHeaderData" @onSort="onSort">
                        </table-header>
                      </template>
                      <template v-slot="props">
                        <div class="is-flex">
                          <b-tooltip class="mr-1" multilined v-for="coupon in props.row.usedCoupons" :key="coupon.id">
                            <img :src="coupon.logo" style="width: 32px; height: 32px">
                            <template v-slot:content>
                              <div>
                                <div>{{coupon.name}}</div>
                                <div>Važi do: {{getDate(coupon.expirationTime)}}</div>
                              </div>
                            </template>
                          </b-tooltip>
                        </div>
                      </template>
                    </b-table-column>
                    <b-table-column field="availableCoupons" label="Dostupni kuponi" sortable>
                      <template v-slot:header="{ column }">
                        <table-header :column="column" element-id="2234238697556" header-type="none"
                                      :table-header-data="tableHeaderData" @onSort="onSort">
                        </table-header>
                      </template>
                      <template v-slot="props">
                        <div class="is-flex">
                          <b-tooltip class="mr-1" multilined v-for="coupon in props.row.availableCoupons" :key="coupon.id">
                            <img :src="coupon.logo" style="width: 32px; height: 32px">
                            <template v-slot:content>
                              <div>
                                <div>{{coupon.name}}</div>
                                <div>Važi do: {{getDate(coupon.expirationTime)}}</div>
                              </div>
                            </template>
                          </b-tooltip>
                        </div>

                      </template>
                    </b-table-column>
                  </b-table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TableHeader from "@/components/table-header/table-header.vue";
import UserAvatar from "@/components/avatar/avatar.vue";
import {getDate} from "@/utils/utils";
import CouponCard from "@/components/coupon-card/coupon-card.vue";

export default {
  name: "app-users",
  components: {CouponCard, TableHeader, UserAvatar},
  props:{
    couponId: {
      type: String
    }
  },
  data(){
    return{
      checkedRows:[],
      isLoading: false,
      assignedToUsers: 0
    }
  },
  async mounted() {
    console.log('Coupon id: ', this.couponId)
    this.isLoading = true
    if(this.$store.state.coupons.length === 0){
      await this.$store.dispatch('getCoupons')
    }
    if(this.coupon){
      let couponCost = this.coupon.couponCost
      let data = {
        limit: couponCost - 1,
        pageNum: 0,
        pageSize: 20
      }
      await this.$store.dispatch('getUserCards', data)
    }

    this.cardTableData.forEach(item=>{
      console.log('Coupons for user:', Object.keys(item.coupons))
      if(Object.keys(item.coupons).includes(this.couponId)){
        console.log('Coupons for user: INCLUDES')
        this.assignedToUsers++
      }
    })

    this.isLoading = false
  },
  methods:{
    getDate,
    sortClicked(field, order){
      console.log('Sorted: ', field, order)
      let tableHeaderData = this.tableHeaderData
      tableHeaderData.activeSorted = {field: field, order:order}
      this.$store.dispatch('setTableHeaderData',tableHeaderData)
    },
    onSort(tableHeaderData){
      this.$store.dispatch('setTableHeaderData',tableHeaderData)
    },
    async assignCoupon(){
      let userIds = this.checkedRows.map(card=>card.userId)
      console.log('Assign coupon to users', userIds)
      let data = {
        couponId: this.coupon.id,
        userIds: userIds
      }

      this.isLoading = true
      let response = await this.$store.dispatch('assignCoupon', data)
      if(response.success){
        this.assignedToUsers = response.assignedUserIds.length
        let couponCost = this.coupon.couponCost

        let data = {
          limit: couponCost - 1,
          pageNum: 0,
          pageSize: 20
        }

        await this.$store.dispatch('getUserCards', data)
      }
      this.isLoading = false

    }
  },
  computed:{
    cardTableData(){
      if(this.$store.getters.getCardsTable && this.$store.getters.getCardsTable.length > 0){
        return this.$store.getters.getCardsTable
      }
      return []
    },
    tableHeaderData(){
      return this.$store.state.tableHeaderData
    },
    coupon(){
      return this.$store.getters.getCouponById(this.couponId)
    }
  }
}
</script>

<style scoped>

</style>