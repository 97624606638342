<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>

    <b-modal v-model="modalActive" width="60%" scroll="keep" @close="clearFields()">
      <div class="card">
        <header class="card-header">
          <div class="card-header-title is-flex">
            <h6 class="is-size-6 ml-auto mr-auto pl-4 has-text-weight-semibold">
              {{ this.isEditMode ? 'Izmena Kupona' : 'Dodaj novi kupon' }}</h6>
          </div>
          <b-button class="card-header-icon mt-2 mr-2" aria-label="more options" @click="show(false, false)">
            <b-icon icon="close"></b-icon>
          </b-button>
        </header>
        <div class="card-content">
          <div class="content">
            <b-field>
              <b-switch v-model="coupon.welcomeCoupon">
                {{ coupon.welcomeCoupon ? 'Welcome' : 'Regular' }}
              </b-switch>
            </b-field>
            <div class="columns mt-2">
              <div class="column pt-4 pb-4 pr-2">
                <b-field label="Naziv kupona">
                  <b-input @blur="isValidForm" @input="isValidForm" ref="name" placeholder="Naziv kupona"
                           v-model="coupon.name" type="text" required></b-input>
                </b-field>
              </div>
              <div class="column pt-4 pb-4 pr-2">
                <b-field label="Tip kupona">
                <b-dropdown ref="type" v-model="coupon.type" aria-role="list" expanded>

                  <template v-if="coupon.type === CouponType.PERCENT" #trigger>
                    <b-button expanded
                              label="Procenti"
                              type="is-primary"
                              icon-left="percent-circle"
                              icon-right="menu-down"/>
                  </template>

                  <template v-else #trigger>
                    <b-button expanded
                              label="Poeni"
                              type="is-primary"
                              icon-left="cash-100"
                              icon-right="menu-down"/>
                  </template>


                  <b-dropdown-item :value="CouponType.PERCENT" aria-role="listitem">
                    <div class="media">
                      <b-icon class="media-left" icon="percent-circle"></b-icon>
                      <div class="media-content">
                        <div>Procenti</div>
                        <small>Popust izražen u procentima</small>
                      </div>
                    </div>
                  </b-dropdown-item>

                  <b-dropdown-item :value="CouponType.POINTS" aria-role="listitem">
                    <div class="media">
                      <b-icon class="media-left" icon="cash-100"></b-icon>
                      <div class="media-content">
                        <div>Poeni</div>
                        <small>Popust izražen u poenima (RSD)</small>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column pt-0 pb-4 pr-2">
                <b-field grouped label="Datum isteka">
                  <b-datepicker :mobile-native="false" expanded v-model="coupon.expirationTime" size="is-small"
                                @input="onTimeChanged">
                    <template v-slot:trigger>
                      <b-input expanded readonly placeholder="Datum isteka" :value="startDateString" type="text"
                               required/>
                    </template>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column pt-0 pb-4">
                <b-field label="Popust">
                  <b-input @blur="isValidForm" @input="isValidForm" ref="discount" placeholder="Popust"
                           v-model="coupon.discount" type="number" required></b-input>
                </b-field>
              </div>
              <div class="column pt-0 pb-4">
                <b-field label="Vrednost kupona">
                  <b-input @blur="isValidForm" @input="isValidForm" ref="price" placeholder="Vrednost kupona"
                           v-model="coupon.couponCost" type="number" required></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns mb-0 ">
              <div class="column pt-0 pb-1 mb-0">
                <b-field label="Opis kupona">
                  <b-input maxlength="200" @blur="isValidForm" @input="isValidForm" ref="description" type="textarea"
                           placeholder="Opis kupona" v-model="coupon.description" required></b-input>
                </b-field>
              </div>
            </div>
            <div class="mb-4 mt-0 pt-0">
              <b-field class="file is-primary" :class="{'has-name': !!dropFile}" expanded>
                <b-upload v-model="dropFile" @input="onFileSet" class="file-label" rounded>
                <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label">Dodaj sliku</span>
                </span>
                  <span class="file-name" v-if="dropFile">
                    {{ dropFile.name }}
                </span>
                </b-upload>
              </b-field>
            </div>
            <div class="is-flex is-align-items-center">
              <div class="ml-auto" v-if="dropFile">
                <div>Podešavanje slike</div>
                <vue-cropper
                    ref="cropper"
                    :src="imageSrc"
                    :container-style="{width: '256px', height: '256px'}"
                    :imgStyle="{width: '256px', height: '256px'}"
                    :aspectRatio="1"
                    :cropend="cropEnd"
                    :ready="cropEnd"
                    alt="Source Image">
                </vue-cropper>
              </div>

              <div class="ml-4 mr-auto" v-if="dropFile || croppedImage">
                <div>Rezultat</div>
                <img :src="croppedImage" style="width: 256px; height: 256px">
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer mt-6">
          <b-button class="is-primary is-fullwidth m-2" @click="onSaveClicked" rounded :disabled="!formValid">
            {{ this.isEditMode ? 'Izmeni kupon' : 'Sačuvaj' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import {CouponType} from "@/utils/utils";


export default {
  name: "add-coupon",
  components: {VueCropper},
  data() {
    return {
      isLoading: false,
      modalActive: false,
      isEditMode: true,
      coupon: {
        name: null,
        description: null,
        discount: null,
        expirationTime: null,
        logo: null,
        type: CouponType.PERCENT,
        couponCost: null,
        welcomeCoupon: false,
      },
      formValid: false,
      dropFile: null,
      imageSrc: '/assets/no_logo.png',
      croppedImage: null
    }
  },
  methods: {
    show(show, coupon) {
      this.modalActive = show
      this.isEditMode = coupon != null

      if (this.isEditMode) {
        this.populateFields(coupon)
      }

      if (!this.modalActive) {
        this.clearFields()
      }
    },
    onFileSet() {
      const reader = new FileReader()
      reader.onload = (event) => {
        this.imageSrc = event.target.result
        this.$refs.cropper.replace(this.imageSrc)
      }
      reader.readAsDataURL(this.dropFile)
      console.log('On image set', this.imageSrc)
    },
    cropEnd() {
      this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      console.log('Cropped image:', this.croppedImage)
      this.isValidForm()
    },
    onSaveClicked() {
      if (this.isEditMode) {
        console.log('Update item')
        this.updateItem()
      } else {
        console.log('Add new item')
        this.addNewItem()
      }
    },
    onTimeChanged() {
      this.coupon.expirationTime.setHours(23)
      this.coupon.expirationTime.setMinutes(59)
      this.coupon.expirationTime.setSeconds(59)
      this.coupon.expirationTime.setMilliseconds(999)
    },
    async addNewItem() {
      this.coupon.logo = this.croppedImage

      let expTime = Date.parse(this.coupon.expirationTime)
      console.log('Coupon: ', this.coupon, expTime)

      let data = {
        name: this.coupon.name,
        description: this.coupon.description,
        discount: this.coupon.discount,
        expirationTime: expTime,
        logo: this.coupon.logo,
        type: this.coupon.type,
        welcomeCoupon: this.coupon.welcomeCoupon,
        couponCost: this.coupon.couponCost
      }

      this.isLoading = true
      let result = await this.$store.dispatch('addCoupon', data)
      this.isLoading = false
      if (result.success) {
        this.show(false, false)
      }
    },
    async updateItem() {

      this.coupon.logo = this.croppedImage

      let expTime = Date.parse(this.coupon.expirationTime)
      console.log('Coupon: ', this.coupon, expTime)

      let data = {
        name: this.coupon.name,
        description: this.coupon.description,
        discount: this.coupon.discount,
        expirationTime: expTime,
        logo: this.coupon.logo,
        type: this.coupon.type,
        welcomeCoupon: this.coupon.welcomeCoupon,
        couponCost: this.coupon.couponCost
      }

      this.isLoading = true
      let result = await this.$store.dispatch('updateCoupon', {id: this.coupon.id, coupon: data})
      this.isLoading = false

      if (result.success) {
        this.show(false, false)
      }else{
        this.showError(result.error.message)
      }

    },
    showError(message) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      })
    },
    clearFields() {
      this.coupon.name = null
      this.coupon.description = null
      this.coupon.discount = null
      this.coupon.expirationTime = null
      this.coupon.logo = null
      this.coupon.type = CouponType.PERCENT
      this.coupon.welcomeCoupon = false
      this.coupon.couponCost = null
      this.croppedImage = null
      this.dropFile = null
      this.isValidForm()
    },
    populateFields(coupon) {
      console.log('Populate fields', coupon)
      if (coupon) {
        this.coupon.id = coupon.id
        this.coupon.name = coupon.name
        this.coupon.description = coupon.description
        this.coupon.discount = coupon.discount
        this.coupon.expirationTime = new Date(coupon.expirationTime)
        this.coupon.logo = coupon.logo
        this.coupon.type = coupon.type
        this.coupon.welcomeCoupon = coupon.welcomeCoupon
        this.coupon.couponCost = coupon.couponCost
        this.croppedImage = coupon.logo

        this.isValidForm()
      }
    },
    isValidForm() {
      console.log('Valid: ', this.$refs.name)
      this.formValid = this.coupon.name && this.coupon.description && this.coupon.discount &&
          this.croppedImage && this.coupon.expirationTime && this.coupon.couponCost

      console.log('Is form valid', this.formValid)
    }
  },
  computed: {
    CouponType() {
      return CouponType
    },
    selectedCoupon() {
      return this.$store.getters.selectedCoupon
    },
    startDateString() {
      return this.coupon.expirationTime ? this.coupon.expirationTime.toLocaleString() : ''
    }

  }
}
</script>

<style scoped>
.cropper-style {
  width: 256px;
  height: 256px;
}
</style>