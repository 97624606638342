export default () => ({
  user: null,
  token: null,
  coupons:[],
  selectedCoupon: null,
  userCards:[],
  settings: null,
  tableHeaderData:{
    activeSorted: {
      field: '',
      order: ''
    },
    activeFilterField:'',
    filters:{available: null}
  }
});
