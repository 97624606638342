<template>
  <div class="is-flex">
    <b-tooltip :label="tooltipText" ref="customTooltip" style="position: absolute;"
               :style="{'top': top + 'px', 'left': left + 'px'}"
               position="is-right"
               :active="isActive"
               type="is-dark"
               always>
    </b-tooltip>
    <aside :class="`${isExpanded ? 'is-expanded' : ''}`">
      <!--    <div class="logo">
            <img src="../../assets/logo.png" alt="Vue" />
          </div>-->
      <div class="menu-toggle-wrap">
        <button class="menu-toggle" @click="toggleMenu">
          <b-icon icon="arrow-right" class="material-icons"/>
        </button>
      </div>
      <div class="menu-title">CallCar Loyalty</div>
      <div class="menu is-flex is-flex-direction-column" style="overflow-y: scroll; overflow-x: hidden">

        <router-link v-for="(route, i) in routes" :to="route.path" class="menu-button" :key="i">
          <div class="is-flex" @mouseover="hover" @mouseleave="hover">
            <b-icon :icon="route.meta.icon" class="material-icons"/>
            <span class="text">{{ route.meta.title }}</span>
          </div>
        </router-link>

      </div>


      <div class="flex"></div>

      <!--    <div class="menu">
            <router-link to="/settings" class="menu-button">
              <b-icon icon="account" class="material-icons"/>
              <span class="text">Settings</span>
            </router-link>
          </div>-->
    </aside>
  </div>
</template>

<script>
export default {
  name: 'side-bar',
  data() {
    return {
      isExpanded: false,
      top: 78,
      left: 80,
      isActive: false,
      tooltipText: ''
    }
  },
  mounted() {
    console.log('Custom tooltip:', this.$refs.customTooltip)
  },
  methods: {
    hover(event) {
      console.log('Hover', event.x, event.y)
      console.log('Hover', event)
      if(!this.isExpanded){
        if (event.type === 'mouseleave') {
          this.isActive = false
        } else {
          if (event.relatedTarget.firstChild && event.relatedTarget.firstChild.lastChild) {
            console.log('Title:', event.relatedTarget.firstChild.lastChild.innerText)
            if (event.relatedTarget.firstChild.lastChild.innerText !== undefined && event.relatedTarget.firstChild.lastChild.innerText.length > 0) {
              this.tooltipText = event.relatedTarget.firstChild.lastChild.innerText
              this.isActive = true
              this.top = event.y
            } else {
              this.isActive = false
            }
          } else {
            this.isActive = false
          }
        }
      }else if(this.isActive){
        this.isActive = false
      }

    },
    toggleMenu() {
      this.isExpanded = !this.isExpanded
      localStorage.setItem("is_expanded", this.isExpanded.toString())
      console.log(this.routes)
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500)

      if (this.isExpanded) {
        this.left = 340
      } else {
        this.left = 80
      }
    }
  },
  computed: {
    role() {
      return this.$store.getters.role
    },
    routes() {
      return this.$router.getRoutes().filter((route) => route.meta.icon && route.meta.roles.includes(this.role))
    }
  }
}
</script>

<style lang="scss" scoped>
aside {
  display: flex;
  flex-direction: column;
  box-shadow: 5px 2px 10px rgb(0 0 0 / 20%);

  background-color: var(--dark);
  color: var(--light);

  width: 80px;
  overflow: hidden;
  min-height: 100vh;

  transition: 0.2s ease-in-out;
  z-index: 5;

  .flex {
    flex: 1 1 0%;
  }

  .logo {
    margin-bottom: 1rem;

    img {
      width: 2rem;
    }
  }

  .menu-toggle-wrap {
    display: flex;
    justify-content: flex-end;
    height: 80px;

    position: relative;
    top: 0;
    transition: 0.2s ease-in-out;
    background: #152934;

    .menu-toggle {
      transition: 0.2s ease-in-out;
      width: 80px;
      z-index: 100;

      .material-icons {
        color: var(--light);
        transition: 0.2s ease-out;
      }

      &:hover {
        .material-icons {
          color: var(--primary);
          transform: translateX(0.5rem);
        }
      }
    }
  }

  h3, .menu-button .text {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .menu-title {
    position: absolute;
    font-size: 16px;
    font-weight: 700;
    left: 16px;
    top: 26px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  h3 {
    color: var(--grey);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .menu {
    margin: 0 -1.05rem;
    height: calc(100vh - 80px);
    padding-bottom: 64px;

    .menu-button {
      display: flex;
      align-items: center;
      text-decoration: none;

      transition: 0.2s ease-in-out;
      padding: 1.5rem 2.5rem;

      .material-icons {
        color: var(--light);
        transition: 0.2s ease-in-out;
      }

      .text {
        color: var(--light);
        transition: 0.2s ease-in-out;
        font-size: 7px;
      }

      &:hover {
        background-color: var(--dark-alt);

        .material-icons, .text {
          color: var(--primary);
        }
      }

      &.router-link-exact-active {
        background-color: var(--dark-alt);
        border-right: 5px solid var(--primary);

        .material-icons, .text {
          color: var(--primary);
        }
      }
    }
  }

  .footer {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    p {
      font-size: 0.875rem;
      color: var(--grey);
    }
  }

  &.is-expanded {
    width: var(--sidebar-width);

    .menu-toggle-wrap {
      //top: -3rem;

      .menu-toggle {
        transform: rotate(-180deg);
      }
    }

    h3, .menu-button .text {
      opacity: 1;
      font-size: 16px;
    }

    .menu-title {
      position: absolute;
      font-size: 16px;
      font-weight: 700;
      left: 16px;
      opacity: 1;
    }

    .menu-button {
      .material-icons {
        margin-right: 1rem;
      }
    }

    .footer {
      opacity: 0;
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    z-index: 99;
  }
}
</style>