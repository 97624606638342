<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <add-settings ref="addAppSettings"></add-settings>
    <div class="app-container">
      <div class="app-content">
        <div v-if="settings">
          <div class="box p-0 mr-2 is-flex pl-2 pr-2 pt-2 pb-2 is-flex is-flex-direction-column" style="width: 431px; height: 172px">
            <div class="is-flex is-align-items-center mt-2 ml-2">
              <div>Naziv podešavanja:</div>
              <b-tag class="ml-auto"
                     type="is-warning">
                {{ settings.name}}
              </b-tag>
            </div>
            <div class="is-flex is-align-items-center mt-2 ml-2">
              <div>Auto dodela Welcome kupona:</div>
              <b-tag class="ml-auto"
                     :type="settings.autoAssignWelcomeCoupon ? 'is-success' : 'is-warning'">
                {{ settings.autoAssignWelcomeCoupon}}
              </b-tag>
            </div>

            <div class="is-flex is-align-items-center mt-2 ml-2">
              <div>Procenat poena od vožnje:</div>
              <div class="is-size-4 has-text-weight-bold ml-auto">{{ settings.percentagePerDrive}} %</div>
            </div>

            <div class="pl-4 mt-auto">
              <div class="is-flex is-align-items-center">
                <b-button @click="onEdit(settings)" size="is-small" class="ml-auto">Edit Settings</b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="is-flex is-align-items-center" style="height: 100%">
          <div class="is-flex is-flex-direction-column is-align-items-center m-auto">
            <div class="is-size-5">No Settings</div>
            <b-button type="is-primary" @click="onAddSettings">Add settings</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddSettings from "@/components/add-settings/add-settings.vue";

export default {
  name: "app-settings",
  components: {AddSettings},
  data() {
    return {
      isLoading: false
    }
  },
  async mounted() {
    if (this.$store.state.settings === null) {
      let response = await this.$store.dispatch('getSettings')
      if (!response.success && response.error.code === 'LOYALTY_SETTINGS_NO_EXISTS') {
        console.log('Need to create settings')
      }
    }
  },
  methods:{
    onAddSettings(){
      this.$refs.addAppSettings.show(true, null)
    },
    onEdit(settings){
      this.$refs.addAppSettings.show(true, settings)
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    }
  }
}
</script>

<style scoped>

</style>