<template>
  <div :id="elementId" style="background: white" class="vue-simple-context-menu" v-on-clickaway="onClickOutside">
    <slot></slot>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: "context-search",
  props: {
    elementId: {
      type: String,
      required: true,
    },
  },
  mixins: [ clickaway ],
  data() {
    return {
      item: null,
      visible: false,
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined
    };
  },
  methods:{
/*    onTextChanged(value){
      this.$emit('onTextChanged', value)
    },*/
    showMenu(event, item) {
      console.log('Show menu called', event)

        this.item = item;
        let menu = document.getElementById(this.elementId);
        if (!menu) {
          return;
        }
        menu.style.left = event.pageX - 280 + 'px';
        menu.style.top = 38 + 'px';

        menu.classList.add('vue-simple-context-menu--active');
        this.visible = true
    },
    toggleMenu(event, item) {
      console.log('Show menu called', event)

      if(!this.visible){
        this.item = item;
        let menu = document.getElementById(this.elementId);
        if (!menu) {
          return;
        }
        menu.style.left = event.pageX - 280 + 'px';
        menu.style.top = 38 + 'px';

        menu.classList.add('vue-simple-context-menu--active');
        this.visible = true
      }else{
        this.hideContextMenu()
      }
    },
    hideContextMenu() {
      if(this.visible){
        const element = document.getElementById(this.elementId);
        if (element) {
          element.classList.remove('vue-simple-context-menu--active');
          this.$emit('menu-closed');
        }
        this.visible = false
      }

    },
    onClickOutside() {
      this.hideContextMenu();
    },
    optionClicked(option) {
      this.hideContextMenu();
      this.$emit('option-clicked', {
        item: this.item,
        option: option,
      });
    },
    onEscKeyRelease(event) {
      if (event.keyCode === 27) {
        this.hideContextMenu();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$light-grey: #ecf0f1;
$grey: darken($light-grey, 15%);
$blue: #007aff;
$white: #fff;
$black: #333;
.vue-simple-context-menu {
  background-color: $light-grey;
  border-bottom-width: 0px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba($black, 0.2);
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 1000000;
&--active {
   display: block;
 }
&__item {
   align-items: center;
   color: $black;
   cursor: pointer;
   display: flex;
   padding: 5px 15px;
&:hover {
   background-color: $blue;
   color: $white;
 }
}
&__divider {
   background-clip: content-box;
   background-color: $grey;
   box-sizing: content-box;
   height: 2px;
   padding: 4px 0;
   pointer-events: none;
 }
// Have to use the element so we can make use of `first-of-type` and `last-of-type`
   li {
&:first-of-type {
   margin-top: 4px;
 }
&:last-of-type {
   margin-bottom: 4px;
 }
}
}
</style>