
export default {
  /**
   * Save user data
   * @param state (this state)
   * @param item
   */
  saveToken(state, item) {
    state.token = item;
    localStorage.setItem('token', item)
  },
  saveUser(state, item) {
    state.user = item;
    localStorage.setItem('user', JSON.stringify(item))
  },
  saveCoupons(state, coupons) {
    state.coupons = coupons;
  },
  addCoupon(state, coupon){
    state.coupons.push(coupon)
  },
  updateCoupon(state, coupon){
    let index = state.coupons.findIndex(coup => coup.id === coupon.id)
    if(index !== -1){
      state.coupons.splice(index, 1, coupon)
    }
  },
  setUserCards(state, cards){
    state.userCards = cards
  },
  selectCoupon(state, coupon){
    state.selectedCoupon = coupon
  },
  /**
   * Remove user data
   * @param state (this state)
   * @param item
   */
  removeUserData(state, item) {
    state.user = null
    state.token = null
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  },
  setTableHeaderData(state, newData){
    state.tableHeaderData = newData
  },
  setSettings(state, newData){
    state.settings = newData
  },
  resetTableHeaderData(state){
    state.tableHeaderData = {
      activeSorted: {
        field: '',
        order: ''
      },
      activeFilterField:'',
      filters:{available:null}
    }
  },
};
