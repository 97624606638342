<template src="./template.html"></template>
<script>
export default {
  name: 'Login-Page',
  data() {
    return {
      username: null,
      password: null
    }
  },
  async created(){
    await this.checkAuth()
  },
  methods: {
    async checkAuth(){
      console.log('Check auth: ', this.$store.state.user)
/*      await this.$auth.currentAuthenticatedUser().then((user) => {
        this.$store.commit('saveUserData', user)
        this.$router.push('projects')
      }).catch((error) => {
        console.log(error)
      });*/
    },
    goToRegister(){
      this.$router.push('/register')
    },
    toForgot(){
      this.$router.push('/forgot')
    },
    login: async function () {
      this.$emit('showLoading', true)
      let response = await this.$store.dispatch('login', {username: this.username, password: this.password})
      console.log('Response from login', response)
      if(response.success){
        console.log('Login success')
        let usrResponse = await this.$store.dispatch('getMe')
        if(usrResponse.success){
          this.$emit('showLoading', false)

          console.log('Saved user:', localStorage.getItem('user'))

          await this.$router.push('coupons')
        }else{
          this.loginError(usrResponse.error)
        }
      }else {
        this.loginError(response.error)
      }
    },

    loginError(error) {
      this.$emit('showLoading', false)
      this.$buefy.notification.open({
        duration: 5000,
        message: error.message,
        position: 'is-bottom-right',
        type: 'is-danger',
        hasIcon: false
      })
    }
  }
}
</script>
<style scoped lang="scss" src="./style.scss"></style>
