<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>

    <b-modal v-model="modalActive" width="60%" scroll="keep" @close="clearFields()">
      <div class="card">
        <header class="card-header">
          <div class="card-header-title is-flex">
            <h6 class="is-size-6 ml-auto mr-auto pl-4 has-text-weight-semibold">
              {{ this.isEditMode ? 'Izmena Podešavanja' : 'Dodaj nova podešavanja' }}</h6>
          </div>
          <b-button class="card-header-icon mt-2 mr-2" aria-label="more options" @click="show(false, false)">
            <b-icon icon="close"></b-icon>
          </b-button>
        </header>
        <div class="card-content">
          <div class="content">
            <b-field>
              <b-switch v-model="settings.autoAssignWelcomeCoupon">
                {{ settings.autoAssignWelcomeCoupon ? 'Auto Dodela Welcome Kupona' : 'Bez Dodele Welcome Kupona' }}
              </b-switch>
            </b-field>
            <div class="columns mt-2">
              <div class="column pt-4 pb-4 pr-2">
                <b-field label="Procenat od vožnje za kalkulaciju poena">
                  <b-input @blur="isValidForm" @input="isValidForm" ref="name" placeholder="Procenat od vožnje za kalkulaciju poena"
                           v-model="settings.percentagePerDrive" type="number" required></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer mt-6">
          <b-button class="is-primary is-fullwidth m-2" @click="addUpdateItem" rounded :disabled="!formValid">
            {{ this.isEditMode ? 'Izmeni Podešavanja' : 'Sačuvaj podešavanja' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "add-settings",
  data(){
    return {
      isLoading: false,
      modalActive: false,
      isEditMode: false,
      settings: {
        percentagePerDrive: null,
        autoAssignWelcomeCoupon: true,
      },
      formValid: false,
    }
  },
  methods:{
    show(show, settings) {
      this.modalActive = show
      this.isEditMode = settings != null

      if (this.isEditMode) {
        this.populateFields(settings)
      }

      if (!this.modalActive) {
        this.clearFields()
      }
    },
    isValidForm() {
      this.formValid = this.settings.percentagePerDrive
    },
    async addUpdateItem(){
      console.log('Add update item')
      this.isLoading = true
      let settings = {
        percentagePerDrive: this.settings.percentagePerDrive,
        autoAssignWelcomeCoupon: this.settings.autoAssignWelcomeCoupon
      }
      let response = await this.$store.dispatch('addChangeSettings', settings)
      if(response.success){
        await this.$store.dispatch('getSettings')
        this.clearFields();
        this.show(false, false)
        this.isLoading = false
      }else{
        this.showError(response.error.message)
      }
    },
    showError(message) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      })
    },
    clearFields(){
      this.settings.percentagePerDrive = null
      this.settings.autoAssignWelcomeCoupon = true
    },
    populateFields(settings) {
      console.log('Populate fields', settings)
      if (settings) {
        this.settings.autoAssignWelcomeCoupon = settings.autoAssignWelcomeCoupon
        this.settings.percentagePerDrive = settings.percentagePerDrive

        this.isValidForm()
      }
    },
  }
}
</script>

<style scoped>

</style>