import Vue from 'vue'
import VueRouter from 'vue-router'
import forbiddenPage from "@/views/forbidden/forbidden-page.vue";
import notFound from "@/views/not-found/not-found.vue";
import loginPage from '@/views/login/login.vue';
import { isJwtExpired } from 'jwt-check-expiration';
import appCoupons from "@/views/app/coupons/app-coupons.vue";
import {Role} from "@/utils/utils";
import appUsers from "@/views/app/users/app-users.vue";
import appSettings from "@/views/app/settings/app-settings.vue";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        meta:{
            title: 'Login',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: loginPage
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        meta:{
            title: 'Forbidden',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: forbiddenPage
    },
    {
        path: '/coupons',
        name: 'coupons',
        meta:{
            title: 'Kuponi',
            hasMenu: true,
            isUnrestricted: false,
            hasInternalTransition: false,
            roles: [Role.SYSOP, Role.SYSTEM_ADMIN],
            icon: 'ticket-percent'
        },
        component: appCoupons
    },
    {
        path: '/app-users/:couponId?',
        name: 'app-users',
        meta:{
            title: 'Korisnici',
            hasMenu: true,
            isUnrestricted: false,
            hasInternalTransition: false,
            roles: [Role.SYSOP, Role.SYSTEM_ADMIN],
        },
        component: appUsers,
        props:true,
    },
    {
        path: '/app-settings',
        name: 'app-settings',
        meta:{
            title: 'Loyalty podešavanja',
            hasMenu: true,
            isUnrestricted: false,
            hasInternalTransition: false,
            roles: [Role.SYSOP, Role.SYSTEM_ADMIN],
            icon: 'cog'
        },
        component: appSettings
    },
    {
        path: '*',
        name: 'not-found',
        meta:{
            title: 'Not found',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false,
        },
        component: notFound
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach(async (to, from, next) => {
    //Disable check just for these routes
    console.log('Router next', to)

    if (to.meta.isUnrestricted) {
        //NOT Authorized
        next()
    } else {
        //Authorized
        let token = localStorage.getItem('token')
        if(token && token.length > 0 && !isJwtExpired(token)){
            let userText = localStorage.getItem('user')
            let parsedUser = JSON.parse(userText)
            if(to.meta.roles.includes(parsedUser.role.name)){
                next()
            }else{
                console.log('Navigation guard: Can\'t access this page')
                if(to.name !== 'forbidden') {
                    await router.replace({name: 'forbidden'})
                }
            }

        }else{
            await router.push({ name: 'login' })
        }
/*        await Auth.currentAuthenticatedUser().then((user) => {
            console.log('Route user: ', user)
            let role = user.signInUserSession.idToken.payload['custom:role']
            if(to.meta.roles && to.meta.roles.length > 0){
                if(to.meta.roles.includes(role)){
                    console.log('Calling next')
                    next()
                }else {
                    console.log('Navigation guard: Can\'t access this page')
                    router.replace({ name: 'forbidden' })
                }
            }else{
                console.log('Navigation guard: Provide user roles')
            }
        }).catch((error) => {
            //NOT Authorized, redirect to log in
            if(to.name !== 'login'){
                router.push({ name: 'login' })
            }
            console.log('Navigation guard: ', error)
        });*/
    }
})

export default router
