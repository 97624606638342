const moment = require("moment/moment");


let Role = {
    SYSOP: 'sysop',
    SYSTEM_ADMIN:'systemadmin'
}

let CouponType = {
    PERCENT: 'PERCENT',
    POINTS: 'POINTS'
}

function getDate(date){
    let dateUtc = moment.utc(date);
    let localDate = moment(dateUtc).local();

    return localDate.format('DD.MM.YYYY')
}

module.exports = {
    Role,
    CouponType,
    getDate
}