import Vue from "vue";
export default {
    login:async ({commit}, dataIn) => {
        const data = new URLSearchParams();
        data.append('username', dataIn.username);
        data.append('password', dataIn.password);

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/login',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Login response:', response.data)
            if(response.data.success){
                commit('saveToken', response.data.token)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });

    },
    getMe:async ({commit}) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/users/me'
        }

        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Login response:', response.data)
            if(response.data.success && response.data.user){
                commit('saveUser', response.data.user)
            }else{
                commit('removeUserData')
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    logout:async ({commit}) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/login/logout'
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Login response:', response.data)
            if(response.data.success){
                commit('removeUserData')
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });

    },
    getCoupons:async ({commit}) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/coupons'
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Login response:', response.data)
            if(response.data.success){
                let coupons = []
                coupons.push(...response.data.coupons)
                //coupons.push(...response.data.coupons)
                commit('saveCoupons', coupons)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });

    },
    addCoupon:async ({commit}, coupon) => {
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/coupons',
            headers: {
                'Content-Type': 'application/json'
            },
            data: coupon
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Add coupon response:', response.data)
            if(response.data.success){
                commit('addCoupon', response.data.coupon)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });

    },
    updateCoupon:async ({commit}, data) => {
        let config = {
            method: 'put',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/coupons/' + data.id,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data.coupon
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Add coupon response:', response.data)
            if(response.data.success){
                commit('updateCoupon', response.data.coupon)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    getUserCards: async ({commit}, data)=>{
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/loyalty/list/cards/' + data.limit + '/' + data.pageNum + '/' + data.pageSize
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Get user cards response:', response.data)
            if(response.data.success){
                commit('setUserCards', response.data.cards)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    assignCoupon: async ({commit}, data)=>{
        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/coupons/assign',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Assign coupon response:', response.data)
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    getSettings: async ({commit}, limit)=>{
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/loyalty/list/settings/loyalty/default'
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Get settings response:', response.data)
            if(response.data.success){
                commit('setSettings', response.data.settings)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    addChangeSettings: async ({commit}, dataIn)=>{
        const data = new URLSearchParams();
        data.append('percentagePerDrive', dataIn.percentagePerDrive);
        data.append('autoAssignWelcomeCoupon', dataIn.autoAssignWelcomeCoupon);

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/loyalty/list/settings/loyalty/create',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        return await Vue.prototype.$http(config).then(function (response) {
            console.log('Add change settings response:', response.data)
            if(response.data.success){
                commit('setSettings', response.data.settings)
            }
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    resetPassword: async ({commit}, dataIn)=>{

        const data = new URLSearchParams();
        data.append('email', dataIn.email);
        data.append('token', dataIn.token);
        data.append('password1', dataIn.password1);
        data.append('password2', dataIn.password2);

        let config = {
            method: 'post',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/client_users/password/reset',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        return await Vue.prototype.$http(config).then(function (response) {
            return response.data
        }).catch(function (error) {
            console.log(error);
        });
    },
    selectCoupon: async ({commit}, coupon)=>{
        commit('selectCoupon', coupon)
    },
    setTableHeaderData: async ({commit}, newData) => {
        commit('setTableHeaderData', newData)
    },
    resetTableHeaderData: async ({commit}) => {
        commit('resetTableHeaderData')
    }
}

