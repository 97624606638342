<template>
  <div class="app">
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <side-bar v-if="$router.currentRoute.meta.hasMenu"></side-bar>
    <LayoutDefault>
      <router-view
          @showLoading="showLoading"
          @showMessage="showMessage">
      </router-view>
    </LayoutDefault>
  </div>
</template>

<script>
import LayoutDefault from './layouts/default/default';
import SideBar from "@/components/side-bar/side-bar.vue";

export default {
  name: 'Home-Page',
  components: {
    SideBar,
    LayoutDefault
  },
  data() {
    return {
      transitionName: 'slide-right',
      isLoading: false
    }
  },
  methods: {
    showLoading(show) {
      console.log('Show loading: ', show)
      this.isLoading = show
    },
    showMessage(message, success) {
      this.$buefy.toast.open({
        message: message,
        type: success ? 'is-success' : 'is-danger'
      })
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },
};
</script>

<style lang="scss">
:root {
  --primary: #fff;
  --primary-alt: #d5d5d5;
  --grey: #64748b;
  --dark: #1c5379;
  --dark-alt: #152934;
  --light: #f3f3f3;
  --sidebar-width: 340px;
}
body {
  background: var(--light);
}
button {
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background: none;
}
.app {
  display: flex;
  background: var(--light);
}
</style>
