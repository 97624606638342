
export default {
    token: (state) => {
        return 'Bearer ' + state.user.signInUserSession.idToken
    },
    getUserFullName: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['name']}`
    },
    getUserRole: (state) => {
        return state.user.signInUserSession.idToken.payload['custom:role']
    },
    getUserId: (state) => {
        return state.user.signInUserSession.idToken.payload['sub']
    },
    isAuth: (state) => {
        return !!state.user
    },
    role: state => {
        let userText = localStorage.getItem('user')
        let parsedUser = JSON.parse(userText)
        if(parsedUser) {
            return parsedUser.role.name
        }
        return 'UNDEFINED'
    },
    userAvatar: state => {
        let userText = localStorage.getItem('user')
        let parsedUser = JSON.parse(userText)
        if(parsedUser) {
            return parsedUser.avatar
        }
        return null
    },
    userFullName: state => {
        let userText = localStorage.getItem('user')
        let parsedUser = JSON.parse(userText)
        if(parsedUser) {
            return `${parsedUser.firstName} ${parsedUser.lastName}`
        }
        return 'Undefined Name'
    },
    coupons: state => {
      return state.coupons
    },
    selectedCoupon: state => {
        return state.selectedCoupon
    },
    userCards: state => {
        return state.userCards
    },
    getCouponById: (state) => (couponId) => {
        return state.coupons.find(coupon=>coupon.id === couponId)
    },
    getSettings:(state)=>{
        return state.settings
    },
    getCardsTable: (state) => {
        let filters = state.tableHeaderData.filters
        console.log('Filters: ', filters)
        let table = []
        table = state.userCards.map((td) => {

            let usedCoupons = []
            let availableCoupons = []
            //console.log(Object.entries(td.coupons))

            Object.entries(td.coupons).forEach(entry => {
                let coupon = state.coupons.find(coupon=>coupon.id === entry[0])
                if(entry[1] === null || entry[1] === 0){
                    availableCoupons.push(coupon)
                }else{
                    usedCoupons.push(coupon)
                }
            })

            return {
                userId: td.userId,
                user: td.user,
                coupons: td.coupons,
                usedCoupons: usedCoupons,
                availableCoupons:availableCoupons,
                available: td.available
            }
        })

        let filteredTable = table.filter(dt => filters && filters.available != null ? dt.available.toString().toLowerCase().includes(filters.available.toLowerCase()) : true)
        return filteredTable
    },

}
