<template>
  <div class="box p-0 mr-2 is-flex pl-2 pr-2 pt-2 pb-2" style="width: 431px; height: 142px">
    <div style="width: 96px">
      <b-tag style="width: 100%; height: 18px; margin-bottom: 4px; margin-top: 4px"
             :type="coupon.welcomeCoupon ? 'is-danger' : 'is-warning'">
        {{ coupon.welcomeCoupon ? 'Welcome' : 'Normal' }}
      </b-tag>
      <img style="width: 96px; height: 96px" :src="coupon.logo+'?'+new Date().getUTCMilliseconds()">
    </div>

    <div class="pl-4" style="width: 320px">
      <div class="is-size-6 has-text-weight-bold">{{ coupon.name }}</div>
      <div class="is-size-4 has-text-weight-bold">{{ getDiscount(coupon) }}</div>
      <div class="has-text-grey" style="font-size: 12px; height: 38px">{{ coupon.description }}</div>
      <div class="is-flex is-align-items-center">
        <b-tag style=" height: 18px; margin-right: 4px" type="is-warning">{{ coupon.couponCost }} POENA</b-tag>
        <b-tag style=" height: 18px;" type="is-info">{{ getExpiration(coupon) }}</b-tag>
        <b-tag v-if="isSelected(coupon)" style=" height: 18px;" type="is-info">SELECTED</b-tag>
        <b-button v-if="showActions" @click="onEdit(coupon)" size="is-small" class="ml-auto">Edit</b-button>
        <b-button v-if="showActions && !coupon.welcomeCoupon" @click="onAssignCoupon(coupon)" size="is-small" class="ml-1">Assign</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getDate} from "@/utils/utils";

export default {
  name: "coupon-card",
  props:{
    coupon:{
      type:Object,
      required: true
    },
    showActions:{
      type: Boolean,
      default: true
    }
  },
  methods:{
    getDiscount(coupon) {
      if (coupon.type === 'PERCENT') {
        return `- ${coupon.discount} %`
      } else {
        return `- ${coupon.discount} RSD`
      }
    },
    getExpiration(coupon) {
      return getDate(coupon.expirationTime)
    },
    onAssignCoupon(coupon) {
      this.$router.push({name: 'app-users', params: {couponId: coupon.id}})
/*      if(!this.isSelected(coupon)){
        this.$store.dispatch('selectCoupon', coupon)

      }else{
        this.$store.dispatch('selectCoupon', null)
      }*/
    },
    isSelected(coupon){
      if(this.selectedCoupon){
        return this.selectedCoupon.id === coupon.id
      }
      return false
    },
    onEdit(coupon){
      this.$emit('onEditCoupon', coupon)
    }
  },
  computed:{
    selectedCoupon(){
      return this.$store.getters.selectedCoupon
    }
  }
}
</script>

<style scoped>

</style>