<template>
  <div style="width: 100%">
    <div>
      <b-navbar v-if="$router.currentRoute.meta.hasMenu" :centered="true"
                style="height:80px; box-shadow: 0 3px 10px rgb(0 0 0 / 20%);"
                type="is-default" :active="false">
        <template #brand>
          <img alt="Logo" src="@/assets/call_car_logo_red.png" style="height: 50px; margin-top: 12px; margin-left: 16px">
        </template>
        <template #start>
        </template>
        <template #end>
          <user-dropdown></user-dropdown>
        </template>
      </b-navbar>
    </div>
    <div>
      <transition v-if="!$router.currentRoute.meta.isAuthPage" name="fade" mode="out-in">
        <main :class="{'main-style' : $router.currentRoute.meta.hasMenu}" :key="$router.currentRoute.path">
          <slot/>
        </main>
      </transition>
      <main v-else :class="{'main-style' : $router.currentRoute.meta.hasMenu}">
        <slot/>
      </main>
    </div>
    <footer class="footer app-footer">
      <div class="content is-flex">
        <div style="margin-top: 4px;" class="ml-auto mr-auto">
          Powered by <a href="https://callcar.rs/" style="color: rgba(255,255,255,0.8)">CallCar</a> @ 2023
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
/* eslint-disable */


import UserDropdown from "@/components/user-dropdown/user-dropdown.vue";

export default {
  name: 'LayoutDefault',
  components: {UserDropdown},
  data() {
    return {
      isEditProfileActive: false
    };
  },
  mounted() {
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.bottom-shadow {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.main-style {
  min-height: calc(100vh - 30px - 90px);
  background: #f9f9f9;
}
</style>
